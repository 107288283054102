import { Component, OnInit } from '@angular/core';
import { ConfiguratorImportService } from '../../services/configurator-import.service';
import { FileUploader } from '../../modules/file-upload/file-uploader.class';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-configurator-import',
  templateUrl: './configurator-import.component.html',
  styleUrls: ['./configurator-import.component.scss'],
  providers: [ConfiguratorImportService]
})
export class ConfiguratorImportComponent implements OnInit {
  uploader: FileUploader;
  hasDropZoneOver = false;
  success = false;
  failed = false;
  isSyncing = false;
  sphereId: number;
  syncFailed = false;
  syncSuccess = false;

  constructor(private configuratorImportService: ConfiguratorImportService) {
  }

  ngOnInit(): void {
    this.uploader = this.configuratorImportService.uploader;

    this.configuratorImportService.onUploadCompleted.subscribe(() => {
      this.configuratorImportService.removeAll();
      this.success = true;
    });

    this.configuratorImportService.onUploadError.subscribe(() => {
      this.configuratorImportService.removeAll();
      this.failed = true;
    });
  }

  upload() {
    this.success = false;
    this.failed = false;
    this.configuratorImportService.upload();
  }

  cancel() {
    this.configuratorImportService.removePending();
  }

  fileOver($event: any) {
    this.hasDropZoneOver = $event;
  }

  sync(): void {
    this.isSyncing = true;
    this.configuratorImportService.sync().subscribe(() => {
      this.isSyncing = false;
    });
  }

  syncSphere(): void {
    if (isNullOrUndefined(this.sphereId)) {
      this.failed = true;
      return;
    }

    this.isSyncing = true;
    this.syncFailed = false;
    this.syncSuccess = false;
    this.configuratorImportService.syncSphere(this.sphereId).subscribe(() => {
      this.isSyncing = false;
      this.syncSuccess = true;
    }, (error) => {
      this.isSyncing = false;
      this.syncFailed = true;
    });
  }
}
