<list-toolbar *ngIf="attachmentCartService.isNotEmpty()">
  <ng-container toolbar-actions>
    <img src="/assets/images/icons/documents/toolbar/paper-clip.svg" [alt]="'generic.action.attach'|translate" placement="top"
         [ngbTooltip]="'generic.action.attach'|translate">
  </ng-container>

  <ng-container toolbar-text>
     <span *ngIf="attachmentCartService.count() == 1">
      {{'generic.action.you-selected'|translate}} {{attachmentCartService.count()}} {{'attachment.toolbar.selected-element-single'|translate}}
    </span>
    <span *ngIf="attachmentCartService.count() > 1">
      {{'generic.action.you-selected'|translate}} {{attachmentCartService.count()}} {{'attachment.toolbar.selected-element'|translate}}
    </span>

    <a href="javascript:;" (click)="onConfirm()">{{'attachment.toolbar.upload-attachment'|translate}}</a>
    <span class="sep"> | </span>
    <a href="javascript:;" (click)="onCancel()">{{'attachment.toolbar.cancel-selection'|translate}}</a>
  </ng-container>
</list-toolbar>
