import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '../../common/modals/modal/modal.component';
import { TagResource } from '../../../models/api/tag/tag-resource';
import { Tag } from '../../../models/tag/tag';
import { TagService } from '../../../services/tag.service';
import { DmsElementResource } from '../../../models/api/documents/dms-element-resource';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { validateDate } from '../../../helpers/common/date-utils';
import { LocalDate } from '../../../models/local-date.model';
import { DmsDocClassType } from '../../../models/doc_class_type/dms-doc-class-type';
import { isNullOrUndefined } from 'util';
import { DmsDocClassTypeRepositoryService } from '../../../services/dms-doc-class-type-repository.service';
import { DmsElementMetadataReg } from '../../../models/dms-element-metadata-reg';
import { MetadataFieldType } from '../../../enums/metadata-field-type.enum';
import {
  isBooleanMetadata,
  isDateMetadata,
  isNumberMetadata,
  isStringMetadata
} from '../../../helpers/common/metadata-utils';
import { DmsElementSearchMetadataResource } from '../../../models/api/documents/dms-element-search-metadata-resource';
import * as moment from 'moment';
import { AndOrEnum } from '../../../enums/common/and-or.enum';
import { piCalendarSmall, piClear, PwcIconsLibrary } from "@pwc/icons";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-document-filter-modal',
  templateUrl: './document-filter-modal.component.html',
  styleUrls: ['./document-filter-modal.component.scss']
})
export class DocumentFilterModalComponent implements OnInit {

  @Input() sphereId: number;
  @Input() fiscalYear: number;
  @Output() searchEvent: EventEmitter<DmsElementResource> = new EventEmitter<DmsElementResource>();

  resource = new DmsElementResource();
  selectedTag: string[] = [];
  selectedDocClassTypes: string[] = [];
  selectedMetadata: DmsElementSearchMetadataResource[] = [];
  docClassType: DmsDocClassType[] = [];
  tags = [];
  recent: boolean;
  operator: AndOrEnum = AndOrEnum.AND;
  name: string;

  errorDate = '';
  errorEndDate = '';
  errorCreatedDateFrom = '';
  errorCreatedDateTo = '';

  searchStartDate: number;
  searchEndDate: number;
  createdDateFrom: number;
  createdDateTo: number;

  startDate: LocalDate = null;
  endDate: LocalDate = null;
  createdFrom: LocalDate = null;
  createdTo: LocalDate = null;

  mapMetaDate = new Map<number, LocalDate>();

  @ViewChild('documentFilterModal') documentFilterModal: ModalComponent;

  constructor(private tagService: TagService, private dmsDocClassTypeService: DmsDocClassTypeRepositoryService, private iconsLibrary: PwcIconsLibrary, private translateSrv: TranslateService) {
    iconsLibrary.registerIcons([
      piCalendarSmall,
      piClear
    ]);
  }

  ngOnInit(): void {
  }

  open() {
    this.documentFilterModal.open();
    // this.clearFilters();

    this.loadDates();

    this.name = this.resource.name;
    this.searchStartDate = this.resource.searchStartDate;
    this.searchEndDate = this.resource.searchEndDate;
    this.recent = this.resource.recent;
    this.operator = AndOrEnum.AND;
    this.createdDateFrom = this.resource.createdDateFrom;
    this.createdDateTo = this.resource.createdDateTo;

    this.tags = [];
    this.selectedTag = this.resource.tagCodes;
    const body: Partial<TagResource> = {
      sphereId: this.sphereId
    };
    this.tagService.search(body).pipe()
      .subscribe((res: Tag[]) => {
        this.tags.push(...res);
      });

    this.docClassType = [];
    
    this.selectedMetadata = [];
    this.dmsDocClassTypeService.getBySphere(this.sphereId)
    .subscribe((res: DmsDocClassType[]) => {
      this.docClassType.push(...res);
      // @ts-ignore
      this.selectedDocClassTypes = this.resource.docClassType[0];

        if (this.selectedDocClassTypes.length > 0) {
          this.onSelectedDocClassType(this.selectedDocClassTypes);

          this.selectedMetadata.forEach((value: DmsElementSearchMetadataResource) => {
            this.resource.searchMetadata
              .filter((meta: DmsElementSearchMetadataResource) => meta.code === value.code)
              .forEach((meta: DmsElementSearchMetadataResource) => {
                value.value = meta.value;
              });
          });
        }
      });
  }

  private loadDates(): void {
    this.loadStartDate();
    this.loadEndDate();
    this.loadCreatedDateFrom();
    this.loadCreatedDateTo();
  }

  private loadCreatedDateFrom(): void {
    if (this.resource.createdDateFrom != null) {
      this.createdFrom = new LocalDate(
        new Date(this.resource.createdDateFrom).getFullYear(),
        new Date(this.resource.createdDateFrom).getMonth() + 1,
        new Date(this.resource.createdDateFrom).getDate()
      );
    } else {
      this.createdFrom = null;
    }
  }

  private loadCreatedDateTo(): void {
    if (this.resource.createdDateTo != null) {
      this.createdTo = new LocalDate(
        new Date(this.resource.createdDateTo).getFullYear(),
        new Date(this.resource.createdDateTo).getMonth() + 1,
        new Date(this.resource.createdDateTo).getDate()
      );
    } else {
      this.createdTo = null;
    }
  }

  private loadStartDate(): void {
    if (!isNullOrUndefined(this.resource.searchStartDate)) {
      this.startDate = new LocalDate(new Date(this.resource.searchStartDate).getFullYear(),
        new Date(this.resource.searchStartDate).getMonth() + 1, new Date(this.resource.searchStartDate).getDate());
    } else {
      this.startDate = null;
    }
  }

  private loadEndDate(): void {
    if (!isNullOrUndefined(this.resource.searchEndDate)) {
      this.endDate = new LocalDate(new Date(this.resource.searchEndDate).getFullYear(),
        new Date(this.resource.searchEndDate).getMonth() + 1, new Date(this.resource.searchEndDate).getDate());
    } else {
      this.endDate = null;
    }
  }

  cancel() {
    this.documentFilterModal.close();
  }

  search() {
    this.documentFilterModal.close();
    this.resource.name = ((isNullOrUndefined(this.name) || this.name.length < 3) ? null : this.name);
    this.resource.tagCodes = this.selectedTag;
    this.resource.searchStartDate = this.searchStartDate;
    this.resource.searchEndDate = this.searchEndDate;
    this.resource.createdDateFrom = this.createdDateFrom;
    this.resource.createdDateTo = this.createdDateTo;
    this.resource.recent = this.recent;
    this.resource.searchMetadataOperator = this.operator;

    this.resource.searchMetadata = [];
    this.selectedMetadata.filter((meta: DmsElementSearchMetadataResource) => meta.value != null)
      .forEach((meta: DmsElementSearchMetadataResource) => {
        let val = meta.value;
        if (meta.type === MetadataFieldType.DATE) {
          const date = moment(+meta.value);
          val = date.format('YYYY-MM-DD');
        }
        this.resource.searchMetadata.push({
          name: meta.name,
          code: meta.code,
          type: meta.type,
          value: val
        });
      });

    // Populate doc class types only if metadata values are provided.
    if (this.resource.searchMetadata.length > 0 || this.selectedDocClassTypes.length > 0) {
      const list = [];
      list.push(this.selectedDocClassTypes);
      this.resource.docClassType = list;
    }

    this.searchEvent.emit(this.resource);
  }

  setNgbCreatedDateFrom(date: NgbDate): void {
    if (date == null) {
      this.createdDateFrom = null;
      this.errorCreatedDateFrom = '';
    } else if (date.year >= 1970 && validateDate(date)) {
      this.errorCreatedDateFrom = '';
      this.createdDateFrom = Date.UTC(date.year, date.month - 1, date.day);
    } else {
      this.errorCreatedDateFrom = this.translateSrv.instant('generic.message.date-format-error');
    }
    this.loadCreatedDateFrom();
  }

  setNgbCreatedDateTo(date: NgbDate): void {
    if (date == null) {
      this.createdDateTo = null;
      this.errorCreatedDateTo = '';
    } else if (date.year >= 1970 && validateDate(date)) {
      this.errorCreatedDateTo = '';
      this.createdDateTo = Date.UTC(date.year, date.month - 1, date.day);
    } else {
      this.errorCreatedDateTo = this.translateSrv.instant('generic.message.date-format-error');
    }
    this.loadCreatedDateTo();
  }

  setNgbFiscalStartDate(date: NgbDate) {
    if (isNullOrUndefined(date)) {
      this.searchStartDate = null;
      this.errorDate = '';
    } else if (date.year >= 1970 && validateDate(date)) {
      this.errorDate = '';
      this.searchStartDate = Date.UTC(date.year, date.month - 1, date.day);
    } else {
      this.errorDate = this.translateSrv.instant('generic.message.date-format-error');
    }
    this.loadStartDate();
  }

  setNgbFiscalEndDate(date: NgbDate) {
    if (isNullOrUndefined(date)) {
      this.searchEndDate = null;
      this.errorEndDate = '';
    } else if (date.year >= 1970 && validateDate(date)) {
      this.errorEndDate = '';
      this.searchEndDate = Date.UTC(date.year, date.month - 1, date.day);
    } else {
      this.errorEndDate = this.translateSrv.instant('generic.message.date-format-error');
    }
    this.loadEndDate();
  }

  clearFilters() {
    this.resource = new DmsElementResource();
    this.selectedDocClassTypes = [];
    this.selectedMetadata = [];
    this.selectedTag = [];
    this.resource.name = '';
    this.recent = false;

    this.startDate = null;
    this.searchStartDate = null;
    this.endDate = null;
    this.searchEndDate = null;

    this.createdFrom = null;
    this.createdDateFrom = null;
    this.createdTo = null;
    this.createdDateTo = null;
  }

  onSelectedDocClassType(docClassTypeCodes: string[]) {
    // console.debug('selected doc class type', docClassTypeCodes);
    this.selectedMetadata = [];
    this.docClassType.forEach(value => {
      // tslint:disable-next-line:triple-equals
      if (typeof(docClassTypeCodes) === "string" ? docClassTypeCodes === value.code : docClassTypeCodes.includes(value.code)) {
        this.selectedMetadata.push(...value.metadata.filter((meta: DmsElementMetadataReg) => meta.searchable)
          .filter((meta: DmsElementMetadataReg) => this.selectedMetadata.filter((m: DmsElementSearchMetadataResource) => m.code === meta.code).length === 0)
          .map((meta: DmsElementMetadataReg) => ({
            id: meta.id,
            name: meta.name,
            code: meta.code,
            type: meta.type,
            value: null
          })));
      }
    });
  }

  isStringMetadata(type: MetadataFieldType) {
    return isStringMetadata(type);
  }

  isBooleanMetadata(type: MetadataFieldType): boolean {
    return isBooleanMetadata(type);
  }

  isNumberMetadata(type: MetadataFieldType): boolean {
    return isNumberMetadata(type);
  }

  isDateMetadata(type: MetadataFieldType): boolean {
    return isDateMetadata(type);
  }

  // getDatePickerValue(metadata: DmsElementMetadataReg): LocalDate {
  getDatePickerValue(metadata: DmsElementSearchMetadataResource): LocalDate {
    return this.mapMetaDate[metadata.id];
  }

  setNgbMetadata(date: NgbDate, metadataId: number) {
    if (isNullOrUndefined(date)) {
      this.selectedMetadata.forEach(value => {
        if (value.id === metadataId) {
          value.error = false;
          value.value = null;
        }
      });
    } else {
      if (date.year >= 1970 && validateDate(date)) {
        this.selectedMetadata.forEach(value => {
          if (value.id === metadataId) {
            value.error = false;
            value.value = Date.UTC(date.year, date.month - 1, date.day) + '';
          }
        });
      } else {
        this.selectedMetadata.forEach(value => {
          if (value.id === metadataId) {
            value.error = true;
            value.value = null;
          }
        });
      }
    }
  }

  disableSearch(): boolean {
    return Array.isArray(this.selectedDocClassTypes) && this.selectedDocClassTypes.length === 0 
      && this.selectedMetadata.length === 0 && this.selectedTag.length === 0 &&
      (isNullOrUndefined(this.name) || this.name.length < 3) && (isNullOrUndefined(this.recent) || !this.recent)
      && isNullOrUndefined(this.searchStartDate) && isNullOrUndefined(this.searchEndDate)
      && this.createdDateFrom == null && this.createdDateTo == null;
  }
}
