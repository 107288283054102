import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UploaderService } from '../../../services/uploader.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { SphereService } from '../../../services/sphere.service';
import { Sphere } from '../../../models/sphere';
import { FileUploader } from '../../../modules/file-upload/file-uploader.class';
import { isNullOrUndefined } from 'util';
import { LawConservationRectificationModalComponent } from '../law-conservation-rectification-modal/law-conservation-rectification-modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-documents-uploader',
  templateUrl: './documents-uploader.component.html',
  styleUrls: ['./documents-uploader.component.scss']
})
export class DocumentsUploaderComponent implements OnInit {
  @Input() id: string;
  @Input() destinationDirectory: number;
  @Input() fiscalYear: number;

  @Output() closed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('lawConservationRectificationModal') lawConservationRectificationModal: LawConservationRectificationModalComponent;

  uploader: FileUploader;
  hasDropZoneOver = false;
  currentFileId?: number = null;
  multipleSelection = true;
  excludeFromLawConservation = false;

  lawConservation: boolean;

  constructor(private uploaderService: UploaderService,
    private sphereService: SphereService,
    private ngxSmartModalService: NgxSmartModalService,
    private translateSrv: TranslateService) {
  }

  ngOnInit(): void {
    this.uploader = this.uploaderService.uploader;
    this.uploader.onAfterAddingFile = () => this.onAfterAddingFile();
  }

  onAfterAddingFile() {
    if (!this.multipleSelection && this.uploader.queue.length > 1) {
      const latestFile = this.uploader.queue[this.uploader.queue.length - 1];
      this.uploader.queue = [];
      this.uploader.queue.push(latestFile);
    }
  }

  openModal(currentFileId?: number, multipleSelection?: boolean, lawConservation?: boolean) {
    if (isNullOrUndefined(multipleSelection)) {
      this.multipleSelection = true;
    } else {
      this.multipleSelection = multipleSelection;
    }
    this.currentFileId = currentFileId;
    this.lawConservation = lawConservation;
    this.ngxSmartModalService.getModal('uploadModal').open();
  }

  upload() {
    this.sphereService.currentSphere.subscribe((sphere: Sphere) => {
      const data = {
        sphereId: sphere.id,
        parentId: null,
        fiscalYear: this.fiscalYear,
        forceOverrideById: this.currentFileId,
        excludeFromLawConservation: this.excludeFromLawConservation,
        lawConservation: this.lawConservation,
      };
      if (isNullOrUndefined(this.currentFileId)) {
        data.parentId = this.destinationDirectory;
      }
      this.uploaderService.setData(data).upload();
    });

    this.ngxSmartModalService.getModal('uploadModal').close();
    this.closed.emit();
  }

  uploadConfirm() {
    this.excludeFromLawConservation = false;

    if (!this.multipleSelection && this.lawConservation === true) {
      this.lawConservationRectificationModal.open();
    } else {
      this.upload();
    }
  }

  get lawConservationRectificationMessage(): string {
    return this.translateSrv.instant('documents.law.rectify-question');
  }

  confirmLawConservationRectificationModal(): void {
    this.upload();
  }

  negateLawConservationRectificationModal(): void {
    this.excludeFromLawConservation = true;
    this.upload();
  }

  fileOver($event: any) {
    this.hasDropZoneOver = $event;
  }

  cancel() {
    this.uploaderService.removePending();
    this.ngxSmartModalService.getModal('uploadModal').close();
  }

  onFileAdded(file: File[]) {
    // console.log('onFileAdded', file);
  }

}
