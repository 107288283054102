import { AfterViewChecked, ChangeDetectorRef, Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DmsElement } from '../../../../models/dms-element';
import { Observable, of, Subject } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { PageableResponse } from '../../../../models/api/pageable-response';
import { ColumnDef } from '../../../../models/common/data-table/column-def';
import { SortDirectionEnum } from '../../../../enums/common/sort-direction.enum';
import { Router } from '@angular/router';
import { CdkColumnDef } from '@angular/cdk/table';
import { NgbdSortableHeaderDirective, SortEvent } from '../../../../directives/common/sortable.directive';
import { DataTableComponent } from '../../../common/data-table/data-table.component';
import { debounceTime, switchMap } from 'rxjs/operators';
import { Sphere } from '../../../../models/sphere';
import { SphereService } from '../../../../services/sphere.service';
import { LawConservationListSummaryService } from '../../../../services/law-conservation/law-conservation-list-summary.service';
import { DmsElementResource } from '../../../../models/api/documents/dms-element-resource';
import { DocumentTypeEnum } from '../../../../enums/document-type.enum';
import { getIconByFileExtension } from '../../../../helpers/common/generic-utils';
import { FiscalYearResource } from '../../../../models/api/fiscal-year-resource';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { LocalDate } from '../../../../models/local-date.model';
import { DmsDownloadElement } from '../../../../models/dms-download-element';
import { DownloaderService } from '../../../../services/downloader.service';
import { ErrorCode } from '../../../../enums/law-conservation/error-code.enum';

@Component({
  selector: 'app-law-conservation-summary',
  templateUrl: './law-conservation-summary.component.html',
  styleUrls: ['./law-conservation-summary.component.scss'],
  providers: [LawConservationListSummaryService]
})
export class LawConservationSummaryComponent implements OnInit, AfterViewChecked {

  data: DmsElement[] = [];
  pagination: PageableResponse<DmsElementResource[]>;
  columns: ColumnDef<DmsElement>[] = [
    {
      field: 'type',
      header: '',
      cell: this.elementTypeCell,
      sortable: false,
      width: '30px',
      raw: true
    }
  ];
  customColumnsActive: string[] = [];
  customMetaColumnsActive: string[] = [];

  viewChecked = false;

  searchTerm = '';
  searchTermYear: number;
  searchTermError: ErrorCode;
  searchStatus: true | false | null;

  statusCodes: Array<Boolean> = new Array(true, false);

  startDate: LocalDate;
  endDate: LocalDate;

  searchSubject: Subject<string> = new Subject<string>();
  search$: Observable<string> = this.searchSubject.asObservable();

  currentSphereId: number;
  currentUserId: number;
  yearsList: FiscalYearResource[];

  name: string;

  // csv notification var
  public displayCsvInProgressAlert: boolean = false;

  sort: Map<string, SortDirectionEnum> = new Map();
  sortType = '';
  sortReverse = 0;

  errorCodes: ErrorCode[] = [
    ErrorCode.CONFIG_ERROR,
    ErrorCode.EXTENTION_ERROR,
    ErrorCode.FILENAME_ERROR,
    ErrorCode.PHYSICALFILE_ERROR,
    ErrorCode.LD_WC007,
    ErrorCode.LD_IV000
  ];

  @ViewChild('dataTable') dataTable: DataTableComponent<DmsElement>;
  @ViewChildren(NgbdSortableHeaderDirective) headers: QueryList<NgbdSortableHeaderDirective>;
  @ViewChildren(CdkColumnDef) customColumns: QueryList<CdkColumnDef>;

  constructor(private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private sphereService: SphereService,
    private downloaderService: DownloaderService,
    private lawConservationListSummaryService: LawConservationListSummaryService) {
  }

  ngOnInit(): void {
    this.lawConservationListSummaryService.pagination$.subscribe((pagination: PageableResponse<DmsElementResource[]>) => {
      this.pagination = pagination;
    });

    this.refreshList();

    this.loadYearList();

    this.search$.pipe(
      debounceTime(200),
      switchMap(() => this.sphereService.currentSphere),
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of(this.data);
        }
        this.currentSphereId = sphere.id;
        this.currentUserId = sphere.userId;
        const body: Partial<DmsElementResource> = {
          sphereId: sphere.id,
          userId: sphere.userId
        };
        if (!isNullOrUndefined(this.searchTerm) && this.searchTerm !== '' && this.searchTerm.length >= 3) {
          body.name = this.searchTerm;
        }
        if (!isNullOrUndefined(this.searchTermYear) && this.searchTermYear > 0) {
          body.fiscalYear = this.searchTermYear;
        }
        if (!isNullOrUndefined(this.startDate)) {
          body.searchStartDate = Date.UTC(this.startDate.year, this.startDate.month - 1, this.startDate.day);
        }
        if (!isNullOrUndefined(this.endDate)) {
          body.searchEndDate = Date.UTC(this.endDate.year, this.endDate.month - 1, this.endDate.day);
        }
        if (!isNullOrUndefined(this.searchTermError)) {
          body.lawConservationErrorCode = this.searchTermError;
        }
        if (!isNullOrUndefined(this.searchStatus)) {
          body.lawConservation = this.searchStatus;
        }

        if (!isNullOrUndefined(this.sort)) {
          if (isNullOrUndefined(body.sort)) {
            body.sort = '';
            body.sortType = '';
          }
          this.sort.forEach((value, key) => {
            body.sort = key;
            body.sortType = value;
          });
        } else {
          body.sort = null;
        }

        return this.lawConservationListSummaryService.search(body);
      }),
    ).subscribe((res: DmsElement[]) => this.updateElements(res));
  }

  private refreshList() {
    this.sphereService.currentSphere.pipe(
      switchMap((sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of(this.data);
        }
        this.currentSphereId = sphere.id;

        const data: Partial<DmsElementResource> = { sphereId: sphere.id, userId: sphere.userId };
        if (!isNullOrUndefined(this.searchTerm) && this.searchTerm !== '' && this.searchTerm.length >= 3) {
          data.name = this.searchTerm;
        }
        if (!isNullOrUndefined(this.searchTermYear) && this.searchTermYear > 0) {
          data.fiscalYear = this.searchTermYear;
        }
        if (!isNullOrUndefined(this.searchStatus)) {
          data.lawConservation = this.searchStatus;
        }
        if (!isNullOrUndefined(this.startDate)) {
          data.searchStartDate = Date.UTC(this.startDate.year, this.startDate.month - 1, this.startDate.day);
        }
        if (!isNullOrUndefined(this.endDate)) {
          data.searchEndDate = Date.UTC(this.endDate.year, this.endDate.month - 1, this.endDate.day);
        }

        if (!isNullOrUndefined(this.sort)) {
          if (isNullOrUndefined(data.sort)) {
            data.sort = '';
            data.sortType = '';
          }
          this.sort.forEach((value, key) => {
            data.sort = key;
            data.sortType = value;
          });
        } else {
          data.sort = null;
        }
        return this.lawConservationListSummaryService.search(data);
      })
    ).subscribe((res: DmsElement[]) =>
      this.updateElements(res)
    );
  }

  private updateElements(elements: DmsElement[]) {
    this.data = [...elements] || [];
    this.refreshColumns();
  }

  private refreshColumns() {
    let columns = [];
    columns.push('name');
    columns = columns.concat(this.customMetaColumnsActive);
    columns.push('element_fiscal_year');
    columns.push('status');
    columns.push('element_date_execution');
    this.customColumnsActive = columns;
  }

  ngAfterViewChecked(): void {
    this.viewChecked = true;
    this.changeDetectorRef.detectChanges();
  }

  emptyFilters() {
    this.searchTerm = '';
    this.searchTermYear = null;
    this.searchTermError = null;
    this.startDate = null;
    this.endDate = null;
    this.searchStatus = null;
    this.onSearch();
  }

  onSearch() {
    if (!isNullOrUndefined(this.searchTerm) && this.searchTerm.length >= 3) {
      this.searchSubject.next(this.searchTerm);
    } else {
      this.searchSubject.next(null);
    }
  }

  downloadCSV() {
    const data: Partial<DmsElementResource> = { sphereId: this.currentSphereId, userId: this.currentUserId };
    console.log(this.currentUserId)
    console.log("data " , data)
    if (!isNullOrUndefined(this.searchTerm) && this.searchTerm !== '' && this.searchTerm.length >= 3) {
      data.name = this.searchTerm;
    }
    if (!isNullOrUndefined(this.searchTermYear) && this.searchTermYear > 0) {
      data.fiscalYear = this.searchTermYear;
    }
    if (!isNullOrUndefined(this.startDate)) {
      data.searchStartDate = Date.UTC(this.startDate.year, this.startDate.month - 1, this.startDate.day);
    }
    if (!isNullOrUndefined(this.endDate)) {
      data.searchEndDate = Date.UTC(this.endDate.year, this.endDate.month - 1, this.endDate.day);
    }
    if (!isNullOrUndefined(this.searchTermError)) {
      data.lawConservationErrorCode = this.searchTermError;
    }
    if ((!isNullOrUndefined(this.searchStatus))) {
      data.lawConservation = this.searchStatus
    }

    if (!isNullOrUndefined(this.sort)) {
      if (isNullOrUndefined(data.sort)) {
        data.sort = '';
        data.sortType = '';
      }
      this.sort.forEach((value, key) => {
        data.sort = key;
        data.sortType = value;
      });
    } else {
      data.sort = null;
    }
    console.log("data last " , data)
    this.lawConservationListSummaryService.downloadCSV(data).subscribe();
    this.displayCsvInProgressAlert = true;
  }

  setNgbStartDate(date: NgbDate) {
    this.startDate = date;
    this.onSearch();
  }

  setNgbEndDate(date: NgbDate) {
    this.endDate = date;
    this.onSearch();
  }

  onPageChange(page: number): void {

    let sortValues = '';
    if (!isNullOrUndefined(this.sort)) {
      this.sort.forEach((value, key) => {
        sortValues += key + ',' + value;
      });
    } else {
      sortValues = null;
    }

    const data: Partial<DmsElementResource> = {
      sort: sortValues,
      page: page - 1,
      sphereId: this.currentSphereId,
      userId: this.currentUserId
    };
    if (!isNullOrUndefined(this.searchTerm) && this.searchTerm !== '' && this.searchTerm.length >= 3) {
      data.name = this.searchTerm;
    }
    if (!isNullOrUndefined(this.searchTermYear) && this.searchTermYear > 0) {
      data.fiscalYear = this.searchTermYear;
    }
    if (!isNullOrUndefined(this.startDate)) {
      data.searchStartDate = Date.UTC(this.startDate.year, this.startDate.month - 1, this.startDate.day);
    }
    if (!isNullOrUndefined(this.endDate)) {
      data.searchEndDate = Date.UTC(this.endDate.year, this.endDate.month - 1, this.endDate.day);
    }
    if (!isNullOrUndefined(this.searchTermError)) {
      data.lawConservationErrorCode = this.searchTermError;
    }
    if ((!isNullOrUndefined(this.searchStatus))) {
      data.lawConservation = this.searchStatus
    }
    
    this.lawConservationListSummaryService.search(data).pipe()
      .subscribe((res: DmsElement[]) => this.updateElements(res));
  }

  onSort($event: SortEvent) {
    if ($event.direction === SortDirectionEnum.NONE) {
      this.sortReverse = 0;
    } else if ($event.direction === SortDirectionEnum.ASC) {
      this.sortReverse = 1;
    } else if ($event.direction === SortDirectionEnum.DESC) {
      this.sortReverse = 2;
    }

    this.sortType = $event.column;
    if ($event.direction === SortDirectionEnum.NONE) {
      this.sort.delete($event.column);
    } else {
      this.sort.set($event.column, $event.direction);
    }
    this.refreshList();
  }

  // noinspection JSMethodCanBeStatic
  private elementTypeCell(row: DmsElement): string {
    let output = '<div class="outcome-true mr-0">';

    if (row.documentType === DocumentTypeEnum.FOLDER) {
      output += '<img src="/assets/images/icons/directory.svg" alt="Directory">';
    } else {
      output += '<img src="' + getIconByFileExtension(row.extension, row.crossYear) + '" alt="File">';
    }

    output += '</div>';

    return output;
  }

  loadYearList() {
    this.sphereService.currentSphere.pipe(
      // distinctUntilChanged(),
      switchMap((sphere: Sphere) => {
        if (isNullOrUndefined(sphere)) {
          return of([]);
        }
        this.currentSphereId = sphere.id;

        return this.sphereService.getFiscalYears(sphere.id);
      })
    ).subscribe((years: FiscalYearResource[]) => {
      if (!years.length) {
        return;
      }

      this.yearsList = [...years];
      return;
    });
  }

  lawConservationOk(row: DmsElement): boolean {
    return row.lawConservation && (row.lawConservationError == null || row.lawConservationError === '');
  }

  lawConservationNotRun(row: DmsElement): Boolean {
    return row.lawConservation == null || row.lawConservation == undefined;
  }

  lawConservationPath(row: DmsElement): string {
    return row.path.substr(1).split('/').join(' > ');
  }

  lawConservationDateExecution(row: DmsElement): Date {
    return row.lastLawConservationExecution;
  }

  getErrorDescription(code: ErrorCode): string {
    return this.lawConservationListSummaryService.getErrorDescription(code);
  }

  getStatusDescription(code: any): string {
    if (code == null) {
      return 'DA_INVIARE';
    } else if (code == true) {
      return 'OK';
    } else if (code == false) {
      return 'KO';
    }

  }
}
