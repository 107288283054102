<div class="row">
  <div class="col">
    <div class="form-group form-input-group">
      <label class="form-input-label">{{'share.type.select-permission'|translate}}</label>
    </div>
    <div class="custom-control custom-checkbox mb-3">
      <input type="checkbox" class="custom-control-input" id="permissionRead" [(ngModel)]="sharing.permissionRead"
             (ngModelChange)="onPermissionReadChange()" [disabled]="!canEdit">
      <label class="custom-control-label" for="permissionRead">
        {{'share.type.reading'|translate}}
      </label>
    </div>
    <div class="custom-control custom-checkbox mb-3">
      <input type="checkbox" class="custom-control-input" id="permissionWrite" [(ngModel)]="sharing.permissionDownload"
             (ngModelChange)="onPermissionDownloadChange()" [disabled]="!canEdit">
      <label class="custom-control-label" for="permissionWrite">
        {{'share.type.downloading'|translate}}
      </label>
    </div>
    <div class="custom-control custom-checkbox mb-3">
      <input type="checkbox" class="custom-control-input" id="permissionUpload" [(ngModel)]="sharing.permissionUpload"
             (ngModelChange)="onPermissionUploadChange()" [disabled]="!canEdit">
      <label class="custom-control-label" for="permissionUpload">
        {{'share.type.uploading'|translate}}
      </label>
    </div>
  </div>
  <div class="col">
    <div class="form-group form-input-group">
      <label class="form-input-label">{{'share.type.additional-options'|translate}}</label>
    </div>
<!--    TODO: Uncomment the following to allow re-sharing -->
<!--    <div class="custom-control custom-switch mb-3">-->
<!--      <input type="checkbox" class="custom-control-input" id="permissionReSharing"-->
<!--             [(ngModel)]="sharing.permissionReSharing" [disabled]="!isPermissionReSharingEnabled || !canEdit">-->
<!--      <label for="permissionReSharing" class="custom-control-label">{{'share.type.re-sharing'|translate}}</label>-->
<!--    </div>-->

    <div class="custom-control custom-switch mb-3">
      <input type="checkbox" class="custom-control-input" id="permissionDelete"
             [(ngModel)]="sharing.permissionDelete" [disabled]="!isPermissionDeleteEnabled || !canEdit">
      <label for="permissionDelete" class="custom-control-label">{{'share.type.deleting'|translate}}</label>
    </div>

    <div class="custom-control custom-switch mb-3">
      <input type="checkbox" class="custom-control-input" id="permissionLawConservationAllowed"
             [(ngModel)]="sharing.permissionLawConservationAllowed" [disabled]="!isPermissionLawConservationEnabled || !canEdit">
      <label for="permissionLawConservationAllowed" class="custom-control-label">{{'share.type.configure-conservation'|translate}}</label>
    </div>
    <div class="custom-control custom-switch mb-3">
      <input type="checkbox" class="custom-control-input" id="permissionLawConservationDownloadAllowed"
             [(ngModel)]="sharing.permissionLawConservationDownloadAllowed" [disabled]="!isPermissionLawConservationDownloaddEnabled || !canEdit">
      <label for="permissionLawConservationDownloadAllowed" class="custom-control-label">{{'share.type.configure-download-conservation'|translate}}</label>
    </div>
  </div>
</div>

<hr class="mb-4">

<app-share-selected-users [sharing]="sharing"></app-share-selected-users>
