import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FileUploader } from '../../../modules/file-upload/file-uploader.class';
import { UploaderService } from '../../../services/uploader.service';
import { SphereService } from '../../../services/sphere.service';
import { DmsFolderRepositoryService } from '../../../services/dms-folder-repository.service';
import { Sphere } from '../../../models/sphere';
import { ModalComponent } from '../../common/modals/modal/modal.component';
import { DataTableComponent } from '../../common/data-table/data-table.component';
import { FileItem } from '../../../modules/file-upload/file-item.class';
import { UploadFile } from '../../../models/upload-file';
import { CdkColumnDef } from '@angular/cdk/table';
import { FiscalYearResource } from '../../../models/api/fiscal-year-resource';
import { map, switchMap, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';
import { DmsElementResource } from '../../../models/api/documents/dms-element-resource';
import { DocumentTypeEnum } from '../../../enums/document-type.enum';
import { DmsElement } from '../../../models/dms-element';
import { AttachmentCartService } from '../../../services/attachment-cart.service';
import { ConnectionElementResource } from '../../../models/api/connection/connection-element-resource';
import { Subscription } from 'rxjs';
import { ConnectionElementEntryResource } from '../../../models/api/connection/connection-element-entry-resource';
import { DmsElementMetadataRegResource } from '../../../models/api/documents/dms-element-metadata-reg-resource';
import { DmsElementMetadata } from '../../../models/dms-element-metadata';
import { DmsMetadataService } from '../../../services/dms-metadata.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LocalDate } from '../../../models/local-date.model';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-attachment-modal',
  templateUrl: './attachment-modal.component.html',
  styleUrls: ['./attachment-modal.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('close', style({ height: '0px', minHeight: '0', visibility: 'hidden' })),
      state('open', style({ height: '*', visibility: 'visible' })),
      transition('void <=> *', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AttachmentModalComponent {

  @Input() connection: ConnectionElementResource;
  @Output() canceled: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmed: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('attachmentModal') modal: ModalComponent;

  uploader: FileUploader;
  items: UploadFile[] = [];
  uploadedItems: UploadFile[] = [];
  yearsList: FiscalYearResource[] = [];
  sphere: Sphere;
  defaultFolders: number[] = [];

  uploadSubscription: Subscription;
  uploadItemSubscription: Subscription;

  customColumnsActive: string[] = [
    'name',
    'fiscalYear',
    'parent',
    'actions'
  ];

  faCaretDown = faCaretDown;
  faCaretUp = faCaretUp;

  @ViewChild('dataTable') dataTable: DataTableComponent<UploadFile>;
  @ViewChildren(CdkColumnDef) customColumns: QueryList<CdkColumnDef>;

  constructor(private uploaderService: UploaderService,
    private attachmentCartService: AttachmentCartService,
    private sphereService: SphereService,
    private dmsFolderRepositoryService: DmsFolderRepositoryService,
    private dmsMetadataService: DmsMetadataService) {
  }

  open(): void {
    this.uploader = this.uploaderService.uploader;
    this.uploadSubscription = this.uploaderService.onUploadCompleted.subscribe(() => {
      const connection = this.connection;
      connection.elements = [];
      for (const element of this.uploadedItems) {
        const entry = new ConnectionElementEntryResource();
        entry.idElement = element.id;
        entry.fiscalYear = element.fiscalYear;
        connection.elements.push(entry);
      }
      this.dmsFolderRepositoryService.connect(this.connection)
        .subscribe(() => {
          this.confirmed.emit();
        });
    });
    this.uploadItemSubscription = this.uploaderService.onItemCompleted.subscribe((res: DmsElementResource) => {
      const element = res[0];
      const id = element.id;
      const items = this.items.filter((item: UploadFile) => item.item.file.name === element.name);
      if (items.length > 0) {
        const uploadItem = items[0];
        uploadItem.id = id;
        this.uploadedItems.push(uploadItem);
      }
    });
    this.items = this.getItems();
    this.uploadedItems = [];

    this.sphereService.currentSphere.pipe(
      tap((sphere: Sphere) => {
        this.sphere = sphere;
      }),
      switchMap((sphere: Sphere) => this.sphereService.getFiscalYears(sphere.id))
    ).subscribe((years: FiscalYearResource[]) => {
      this.yearsList = [...years];
    });

    this.loadDefaultFolders();

    this.modal.open();
  }

  close(): void {
    this.uploadSubscription.unsubscribe();
    this.uploadItemSubscription.unsubscribe();
    this.modal.close();
  }

  cancel(): void {
    this.attachmentCartService.clear();
    this.uploaderService.removeAll();
    this.modal.close();
    this.canceled.emit();
  }

  submit(): void {
    if (!this.isValid) {
      console.warn('Form invalid!');
      return;
    }

    this.prepareItemsForUpload();
    this.sphereService.currentSphere.subscribe((sphere: Sphere) => {
      this.uploaderService.setData({
        sphereId: sphere.id,
        forceOverrideById: null,
        excludeFromLawConservation: false,
        lawConservation: false,
        connection: this.connection
      }).upload();
    });

    this.modal.close();
  }

  prepareItemsForUpload(): void {
    this.uploader.queue = this.items.map((item: UploadFile) => {
      const file = item.item;
      const data = { ...item };
      data.item = null;
      data.metadata = data.meta.reduce((prev: object, meta: DmsElementMetadata) => {
        prev[meta.elementMetadataReg.code] = meta.value;
        return prev;
      }, {});
      file.formData = data;
      return file;
    });
  }

  get isValid(): boolean {
    return this.items.length > 0 &&
      this.items.filter((item: UploadFile) => isNullOrUndefined(item.fiscalYear) || isNullOrUndefined(item.parentId)).length === 0;
  }

  getItems(): UploadFile[] {
    return this.uploader.queue.map((item: FileItem, index: number) => {
      const obj = new UploadFile();
      obj.item = item;
      obj.index = index;

      return obj;
    });
  }

  searchFolder(row: UploadFile, { term, items }): void {
    row.searchList = row.parentList.filter((element: DmsElement) => element.path.toLowerCase().indexOf(term.toLowerCase()) > -1);
  }

  getFolders(row: UploadFile): void {
    if (isNullOrUndefined(row.fiscalYear)) {
      return;
    }

    const search: Partial<DmsElementResource> = {
      sphereId: this.sphere.id,
      documentType: DocumentTypeEnum.FOLDER,
      fiscalYear: row.fiscalYear,
      searchInTheTree: true
    };

    this.dmsFolderRepositoryService.find(search)
      .subscribe((res: DmsElement[]) => {
        row.parentList = res.filter((element: DmsElement) => element.canCreateFolder);
        row.searchList = [...row.parentList];

        if (this.hasDefaultFolders()) {
          for (const id of this.defaultFolders) {
            let found = false;
            for (const folder of row.parentList) {
              if (folder.id === id) {
                row.parentId = id;
                this.onFolderSelected(row);
                found = true;
                break;
              }
            }
            if (found) {
              break;
            }
          }
        }
      });
  }

  private hasDefaultFolders(): boolean {
    return !isNullOrUndefined(this.defaultFolders) && this.defaultFolders.length > 0;
  }

  onFiscalYearChange(row: UploadFile) {
    row.parentId = null;
    row.parentList = [];
    row.searchList = [];
    this.getFolders(row);
  }

  onFolderSelected(row: UploadFile) {
    this.sphereService.currentSphere.pipe(
      switchMap((sphere: Sphere) => {
        const search: Partial<DmsElementResource> = {
          sphereId: sphere.id,
          id: row.parentId,
          fiscalYear: row.fiscalYear,
          searchInTheTree: false
        };

        return this.dmsFolderRepositoryService.getMetadata(search);
      })
    ).subscribe((metadata: DmsElementMetadataRegResource[]) => {
      // console.debug('metadata', metadata);
      const list = [];
      for (const resource of metadata) {
        const meta = new DmsElementMetadata();
        meta.elementMetadataReg = this.dmsMetadataService.convertMetadataRegToModel(resource);
        list.push(meta);
      }
      row.meta = [...list];
      row.expand = true;
    });
  }

  onDateChange(item: UploadFile, { field, value }: { field: string, value: any }): void {
    item[field] = value.toDate();
  }

  onFileNameChange(item: UploadFile, fileName: string): void {
    item.fileName = fileName;
  }

  toLocalDate(date: Date): LocalDate {
    if (isNullOrUndefined(date)) {
      return null;
    }

    return new LocalDate(date.getFullYear(), date.getMonth() + 1, date.getDay());
  }

  private loadDefaultFolders(): void {
    this.sphereService.currentSphere.pipe(
      switchMap((sphere: Sphere) => {
        return this.dmsFolderRepositoryService.getDefaultAttachmentFolders(sphere.id);
      })
    ).subscribe((res: DmsElement[]) => {
      this.defaultFolders = res.map<number>((folder: DmsElement) => folder.id);
    });
  }
}
