import {MetadataFieldType} from '../../enums/metadata-field-type.enum';
import {DmsElementMetadata} from '../../models/dms-element-metadata';
import {isNullOrUndefined} from 'util';
import * as moment from 'moment';

export function isStringMetadata(type: MetadataFieldType): boolean {
  return type === MetadataFieldType.STRING || type === MetadataFieldType.UNKNOWN;
}

export function isBooleanMetadata(type: MetadataFieldType): boolean {
  return type === MetadataFieldType.BOOLEAN;
}

export function isNumberMetadata(type: MetadataFieldType): boolean {
  return type === MetadataFieldType.NUMBER;
}

export function isDateMetadata(type: MetadataFieldType): boolean {
  return type === MetadataFieldType.DATE;
}

export function getMetadataFormattedValue(metadata: DmsElementMetadata, locale: string): string {
  if (isBooleanMetadata(metadata.elementMetadataReg.type)) {
    if (metadata.value === 'true') {
      return 'SI';
    } else {
      return 'NO';
    }
  } else if (isNullOrUndefined(metadata.value) || metadata.value.length === 0) {
    return '';
  } else if (isDateMetadata(metadata.elementMetadataReg.type)) {
    if (isNullOrUndefined(metadata.value) || metadata.value.length === 0) {
      return '';
    } else {
      return moment(metadata.value).format('DD/MM/YYYY');
    }
  } else {
    return metadata.value;
  }
}
