import { Component, EventEmitter, Inject, Input, LOCALE_ID, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DmsElement } from '../../../models/dms-element';
import { faAngleDown, faAngleUp, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { DmsFileRepositoryService } from '../../../services/dms-file-repository.service';
import { DmsFileHistoryResource } from '../../../models/api/dms-file-history-resource';
import { isNullOrUndefined } from 'util';
import { DocumentTypeEnum } from '../../../enums/document-type.enum';
import { SharingElementsService } from '../../../services/share/sharing-elements.service';
import { DmsElementMetadata } from '../../../models/dms-element-metadata';
import { getMetadataFormattedValue } from '../../../helpers/common/metadata-utils';
import { Tag } from '../../../models/tag/tag';
import { TagService } from '../../../services/tag.service';
import { BadgeListItem } from '../../../models/common/badge-list-item';
import { TagResource } from '../../../models/api/tag/tag-resource';
import { ModalComponent } from '../../common/modals/modal/modal.component';
import { FiscalYearResource } from '../../../models/api/fiscal-year-resource';
import { EventElementsService } from '../../../services/events/event-elements.service';
import { ApplicationEvent } from '../../../models/event/application-event';
import { CdkColumnDef } from '@angular/cdk/table';
import { getIconByFileExtension } from '../../../helpers/common/generic-utils';
import { DmsFolderRepositoryService } from '../../../services/dms-folder-repository.service';
import { ConnectionGroup } from '../../../models/connection/connection-group';
import { TagTypeEnum } from '../../../enums/tag-type.enum';
import { LawConservationVersion } from '../../../models/law-conservation/law-conservation-version';
import { ConnectionAlias } from '../../../models/connection/connection-alias';
import { LawConservationService } from '../../../services/law-conservation/law-conservation.service';
import { DmsSharingResource } from '../../../models/api/share/dms-sharing-resource';
import { SphereService } from '../../../services/sphere.service';
import { first, switchMap } from 'rxjs/operators';
import { Sphere } from '../../../models/sphere';
import { DmsSharing } from '../../../models/share/dms-sharing';
import { piCalendarSmall, piClear, piDelete, piDownload, piEdit, piExport, piView, PwcIconsLibrary } from "@pwc/icons";

@Component({
  selector: 'app-document-metadata',
  templateUrl: './document-metadata.component.html',
  styleUrls: ['./document-metadata.component.scss']
})
export class DocumentMetadataComponent {
  @Input() dmsElement: DmsElement;
  @Input() selectedYear: FiscalYearResource;
  @Input() path: DmsElement[];

  @Output() openElementPreview: EventEmitter<number> = new EventEmitter<number>();
  @Output() openFileDetailEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() downloadFileDetailEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() tagDeleted: EventEmitter<void> = new EventEmitter<void>();
  @Output() connectionDeleted: EventEmitter<void> = new EventEmitter<void>();
  @Output() closed: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('infoModal') infoModal: ModalComponent;
  @ViewChild('deleteTagModal') deleteTagModal: ModalComponent;
  @ViewChild('deleteConnectionModal') deleteConnectionModal: ModalComponent;
  @ViewChild('deleteAliasModal') deleteAliasModal: ModalComponent;

  faAngleDown = faAngleDown;
  faAngleUp = faAngleUp;
  faTimesCircle = faTimesCircle;
  faCheckCircle = faCheckCircle;

  fileHistory: DmsFileHistoryResource[] = [];
  tags: Tag[] = [];
  info: string[] = [];
  connections: ConnectionGroup[] = [];
  aliases: ConnectionAlias[] = [];

  selectedTag: Tag;
  selectedConnection: ConnectionGroup;
  selectedAlias: ConnectionAlias;
  activeIds: string[] = [];
  events: ApplicationEvent[] = [];
  @ViewChildren(CdkColumnDef) eventColumns: QueryList<CdkColumnDef>;
  eventColumnsActive: string[] = [
    'event_type',
    'user',
    'event_date'
  ];

  showAllHasResponse: boolean;
  lawConservationVersionList: LawConservationVersion[];

  iconPath: string;

  constructor(private dmsFileRepositoryService: DmsFileRepositoryService,
    private tagService: TagService,
    private eventService: EventElementsService,
    private sharingElementsService: SharingElementsService,
    private dmsFolderRepositoryService: DmsFolderRepositoryService,
    private sphereService: SphereService,
    private lawConservationService: LawConservationService,
    @Inject(LOCALE_ID) public locale: string,
    private iconsLibrary: PwcIconsLibrary) {
    iconsLibrary.registerIcons([
      piClear,
      piView,
      piEdit,
      piDelete,
      piCalendarSmall,
      piExport,
      piDownload
    ]);
  }

  open() {
    this.activeIds = [];
    this.infoModal.open();
    this.showAllHasResponse = false;
    this.lawConservationVersionList = [];
    this.connections = [];
    this.aliases = [];
    this.tags = [];
    this.info = [];


    setTimeout(() => {
      if (!isNullOrUndefined(this.dmsElement)) {

        if (this.isFile) {
          this.dmsFileRepositoryService.loadVersions(this.dmsElement.id).subscribe(value => {
            this.fileHistory = [];
            value.history.forEach(value1 => this.fileHistory.push(value1));
          });

          this.loadConnections();
        }

        this.tagService.getTags(this.dmsElement.id, this.selectedYear.year).subscribe(value => {
          value.forEach(value1 => this.tags.push(value1));
          this.getTagBadges();
        });
        if (this.isFolderLawConserved(this.dmsElement)) {
          const lawConservationTag = new Tag();
          lawConservationTag.code = 'conservato';
          lawConservationTag.description = 'conservato';
          this.tags.push(lawConservationTag);
        }

        this.loadSharingInfo();

        this.refreshEvents();

        this.iconPath = getIconByFileExtension(this.dmsElement.extension, this.dmsElement.crossYear);
      }
    }, 100);
  }

  private refreshEvents() {
    this.events = [];
    this.eventService.search({ elementId: this.dmsElement.id, sphereId: this.dmsElement.sphereId, sort: '' })
      .subscribe((res: ApplicationEvent[]) => {
        this.events = [...res].slice(0, 5);
      });
  }

  isFolderLawConserved(elem: DmsElement): boolean {
    if (elem.documentType === DocumentTypeEnum.FOLDER) {
      if ((elem.configurationId !== null && elem.configurationId > 0) || (elem.configurationIdParent !== null && elem.configurationIdParent > 0)
        && (elem.excludeFromLawConservation == null || elem.excludeFromLawConservation === false)
        && (elem.excludeParentFromLawConservation == null || elem.excludeParentFromLawConservation === false)) {
        return true;
      }
    }
    return false;
  }

  loadSharingInfo(): void {
    this.sphereService.currentSphere.pipe(
      switchMap((sphere: Sphere) => {
        const search: Partial<DmsSharingResource> = {
          sphereId: sphere.id,
          elementId: this.dmsElement.id,
          fiscalYearId: this.selectedYear.id
        };
        return this.sharingElementsService.searchByElement(search);
      })
    ).subscribe((list: DmsSharing[]) => {
      this.info = [];
      for (const sharing of list) {
        this.info.push('Nome: ' + sharing.name);
        this.info.push('Data: ' + new Date(sharing.sharingDate).toLocaleDateString());
        sharing.share2Users.forEach(value2 => {
          this.info.push(value2.username + '  (' + value2.email + ')');
        });
        this.info.push('');
        this.info.push('');
      }
    });
  }

  loadConnections(): void {
    this.sphereService.currentSphere.pipe(
      first(),
      switchMap((sphere: Sphere) => {
        const sphereId = sphere.id;
        const elementId = this.dmsElement.id;

        return this.dmsFolderRepositoryService.getConnectionGroups(elementId, sphereId);
      })
    ).subscribe((connections: ConnectionGroup[]) => {
      this.connections = connections;
    });

    this.sphereService.currentSphere.pipe(
      first(),
      switchMap((sphere: Sphere) => {
        const sphereId = sphere.id;
        const elementId = this.dmsElement.id;

        return this.dmsFolderRepositoryService.getConnectionAliases(elementId, sphereId);
      })
    ).subscribe((connections: ConnectionAlias[]) => {
      this.aliases = connections;
    });
  }

  onClose($event: string) {
    this.closed.emit($event);
  }

  openFileDetail(historyFileId: number): void {
    this.openFileDetailEvent.emit(historyFileId);
  }

  previewElement(elementId: number): void {
    this.openElementPreview.emit(elementId);
  }

  downloadDocument(historyFileId: number) {
    this.downloadFileDetailEvent.emit(historyFileId);
  }

  get isFile(): boolean {
    return !isNullOrUndefined(this.dmsElement) && this.dmsElement.documentType === DocumentTypeEnum.FILE;
  }

  get isFolder(): boolean {
    return !isNullOrUndefined(this.dmsElement) && this.dmsElement.documentType === DocumentTypeEnum.FOLDER;
  }

  get isLawConservation(): boolean {
    return this.isFile
      && (this.dmsElement.configurationId != null || this.dmsElement.configurationIdParent != null)
      && (this.dmsElement.lawConservation || this.dmsElement.lawConservationAtLeastOneVersion ||
        (this.dmsElement.lawConservatioNote !== null && this.dmsElement.lawConservatioNote !== ''));
  }

  getMetadataValue(metadata: DmsElementMetadata): string {
    return getMetadataFormattedValue(metadata, this.locale);
  }

  removeTag(id: any): void {
    const tags = this.tags.filter((item: Tag) => item.id === id);
    if (tags.length > 0) {
      this.selectedTag = tags[0];
      this.deleteTagModal.open();
    }
  }

  tagsList: Array<any> = new Array<any>();

  getTagBadges(): BadgeListItem[] {
    if (isNullOrUndefined(this.tags)) {
      return [];
    }
    this.tagsList = this.tags.map((tag: Tag) => ({
      id: tag.id,
      label: tag.code,
      canRemove: ((tag.type !== TagTypeEnum.SYSTEM || tag.code === 'favorite') && !(tag.code === 'conservato'))
    }));
  }

  getTagDescription(): string {
    if (isNullOrUndefined(this.selectedTag)) {
      return '';
    } else {
      return this.selectedTag.description;
    }
  }

  deleteTag() {
    const body = new TagResource();
    body.fiscalYearId = this.selectedYear.id;
    body.user = this.selectedTag.user;
    body.idElements.push(this.dmsElement.id);
    body.sphereId = this.dmsElement.sphereId;
    body.tagElementEntries = [
      {
        id: this.selectedTag.id,
        code: this.selectedTag.code,
        sphereId: this.dmsElement.sphereId,
        requiredInsert: false,
        onlyForMe: false
      }
    ];
    this.tagService.removeTag(body).subscribe(() => {
      this.tags = this.tags.filter((item: Tag) => item.id !== this.selectedTag.id);
      this.selectedTag = null;
      this.deleteTagModal.close();
      this.refreshEvents();
      this.getTagBadges();
      this.tagDeleted.emit();
    });
  }

  formatPath(path: string): string {
    return path.substring(1, path.length).replace(new RegExp('/', 'g'), ' > ');
  }

  getEventTypeLabel(eventType: string) {
    return this.eventService.getEventTypeLabel(eventType);
  }

  deleteConnection(connection: ConnectionGroup) {
    this.selectedConnection = connection;
    this.deleteConnectionModal.open();
  }

  deleteAlias(alias: ConnectionAlias) {
    this.selectedAlias = alias;
    this.deleteAliasModal.open();
  }

  doDeleteConnection(): void {
    if (isNullOrUndefined(this.selectedConnection)) {
      return;
    }
    this.dmsFolderRepositoryService.deleteConnectionGroup(this.selectedConnection.id)
      .subscribe((res: boolean) => {
        if (res) {
          this.loadConnections();
          this.deleteConnectionModal.close();
          this.connectionDeleted.emit();
          this.infoModal.close();
        }
      });
  }

  doDeleteAlias(): void {
    if (isNullOrUndefined(this.selectedAlias)) {
      return;
    }

    this.dmsFolderRepositoryService.deleteConnectionAlias(this.selectedAlias.id)
      .subscribe((res: boolean) => {
        if (res) {
          this.loadConnections();
          this.deleteAliasModal.close();
        }
      });
  }

  get allConnectionsLength(): number {
    return this.connections.length + this.aliases.length;
  }

  getAllLawConservationVersion(dmsElement: DmsElement) {
    this.lawConservationService.getAllVersions(dmsElement, this.selectedYear.year).subscribe(value =>
      this.lawConservationVersionList = value);
  }

  hasAtLeastOneVersionLawConserved(dmsElement: DmsElement): boolean {
    return !dmsElement.lawConservation && dmsElement.lawConservationAtLeastOneVersion;
  }

  newVersionNotConserved(element: DmsElement): boolean {
    return element.excludeFromLawConservation && element.lastLawConservationExecution != null;
  }
}
