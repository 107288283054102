import { AfterViewInit, ChangeDetectorRef, Component, HostListener, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Data, Router, RouterEvent, RoutesRecognized } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationCookie } from '@pwc/security';
import { Observable, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Language } from './models/common/language';
import { I18nService } from './services/common/i18n.service';
import { AuthenticationService } from 'src/app/services/common/authentication.service';

export let AppInjector: Injector;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {
  languages: Language[] = [];
  currentLanguage: string;

  title = 'Documentale';
  currentUser: AuthenticationCookie;

  isAuthorized = false;
  private routeData: Subject<Data> = new Subject();
  private routeData$: Observable<Data> = this.routeData.asObservable();

  @ViewChild('sideBar') sideBar: any;
  @ViewChild('header') header: any;
  private cookie: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private i18n: I18nService,
    private changeDetector: ChangeDetectorRef,
    injector: Injector,
  ) {
    AppInjector = injector;
    translate.setDefaultLang('it');

    this.authenticationService.cookie
      .subscribe((x: AuthenticationCookie) => {
        if (x) {
          this.currentUser = x;
        } else {
          this.currentUser = undefined;
        }
      });
  }

  ngOnInit() {
    this.i18n.lang.subscribe((lang: string) => {
      this.currentLanguage = lang;
    });
    this.languages = this.i18n.languages;
  }

  ngAfterViewInit(): void {
    this.resize(window.innerWidth);
  }

  private authorizeForRoute(): void {
    // We are outside the router-outlet, so we should hook into router events and find out when the route gets recognized.
    // this.router.events.subscribe((event: RouterEvent) => {
    //   if (event instanceof RoutesRecognized) {
    //     this.routeData.next(event.state.root.firstChild.data);
    //   }
    // });

    this.router.events.pipe(
      filter((event: RouterEvent) => event instanceof RoutesRecognized),
      map((event: RoutesRecognized): Data => event.state.root.firstChild.data)
    ).subscribe((data: Data) => {
      this.isAuthorized = false;
      if (typeof data.authorities === 'undefined' || data.authorities.length === 0) {
        console.info('Route authorization: no authorities required for the current route - authorized: YES');
        this.isAuthorized = true;
        return;
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const w = event.target.innerWidth;
    this.resize(w);
  }

  resize(width: number) {
    const component = this;
    if (!this.cookie || typeof this.sideBar === 'undefined') {
      return;
    }
    if (width < 1200) {
      if (!this.sideBar.active) {
        this.sideBar.active = true;
        this.sideBar.showDesc = false;
      }
    } else {
      if (this.sideBar.active) {
        this.sideBar.active = false;
        setTimeout(() => {
          component.sideBar.showDesc = true;
        }, 500);
      }
    }
  }

  logout() {
    this.authenticationService.logout();
  }
}


