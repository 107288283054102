<dl class="row">
  <dt class="col-sm-4 metadata-label">{{'documents.detail.file-name'|translate}}</dt>
  <dd class="col-sm-8 metadata-value">
    <div class="form-group form-input-group">
      <input type="text" class="form-control form-input no-shadow" [ngModel]="fileName"
        (ngModelChange)="onFileNameChange($event)">
    </div>
    <div class="form-group form-input-group" *ngIf="!isFileNameValid">
      <label class="text-danger p-3">{{'attachment.metadata.name-file-error'|translate}}</label>
    </div>
  </dd>
</dl>

<dl class="row">
  <dt class="col-sm-4 metadata-label">{{'attachment.metadata.document-date'|translate}}</dt>
  <dd class="col-sm-8 metadata-value">
    <div class="form-group form-input-group withError">
      <input type="text" class="form-control form-input no-shadow mr-2" placeholder="DD/MM/YYYY"
        (ngModelChange)="setNgbDate('documentDate', $event)" [ngModel]="localDocumentDate" ngbDatepicker
        #d0="ngbDatepicker">
      <div (click)="d0.toggle()" class="form-input-icon append pointer w-auto">
        <pwc-icon name="calendar_small"></pwc-icon>
      </div>
      <div *ngIf="errors?.documentDate" class="invalid-feedback" style="padding-top: 5px">{{errors?.documentDate}}
      </div>
    </div>
  </dd>
</dl>

<dl class="row">
  <dt class="col-sm-4 metadata-label">{{'generic.field.start-date'|translate}}</dt>
  <dd class="col-sm-8 metadata-value">
    <div class="form-group form-input-group withError">
      <input type="text" class="form-control form-input mandatory no-shadow mr-2" placeholder="DD/MM/YYYY"
        (ngModelChange)="setNgbDate('fiscalStartDate', $event)" [ngModel]="localFiscalStartDate" ngbDatepicker
        #d1="ngbDatepicker">
      <div (click)="d1.toggle()" class="form-input-icon append pointer w-auto">
        <pwc-icon name="calendar_small"></pwc-icon>
      </div>
      <div *ngIf="errors?.fiscalStartDate" class="invalid-feedback" style="padding-top: 5px">
        {{errors?.fiscalStartDate}}
      </div>
    </div>
  </dd>
</dl>

<dl class="row">
  <dt class="col-sm-4 metadata-label">{{'generic.field.end-date'|translate}}</dt>
  <dd class="col-sm-8 metadata-value">
    <div class="form-group form-input-group withError">
      <input type="text" class="form-control form-input mandatory no-shadow" placeholder="DD/MM/YYYY"
        (ngModelChange)="setNgbDate('fiscalEndDate', $event)" [ngModel]="localFiscalEndDate" ngbDatepicker
        #d2="ngbDatepicker">
      <div (click)="d2.toggle()" class="form-input-icon append pointer w-auto">
        <pwc-icon name="calendar_small"></pwc-icon>
      </div>
      <div *ngIf="errors?.fiscalEndDate" class="invalid-feedback" style="padding-top: 5px">
        {{errors?.fiscalEndDate}}
      </div>
    </div>
  </dd>
</dl>

<ng-container *ngFor="let meta of metadata; let i=index">
  <dl *ngIf="!meta.elementMetadataReg?.explicitNotVisible"
    class="row">
    <dt class="col-sm-4 metadata-label" [ngClass]="{mandatory: meta.elementMetadataReg?.required}">
      {{meta.elementMetadataReg?.name}}</dt>
    <dd class="col-sm-8 metadata-value">
      <div class="form-group form-input-group" *ngIf="isString(meta.elementMetadataReg?.type)">
        <input type="text" class="form-control form-input no-shadow"
          [ngClass]="{mandatory: meta.elementMetadataReg?.required}" [(ngModel)]="meta.value">
      </div>
      <div class="form-group form-input-group" *ngIf="isNumber(meta.elementMetadataReg?.type)">
        <input type="number" class="form-control form-input no-shadow"
          [ngClass]="{mandatory: meta.elementMetadataReg?.required}" [(ngModel)]="meta.value">
      </div>
      <div class="form-group form-input-group" *ngIf="isBoolean(meta.elementMetadataReg?.type)"
        style="vertical-align: middle;">
        <input type="checkbox" class="form-control form-input no-shadow" [(ngModel)]="meta.value"
          *ngIf="!meta.elementMetadataReg?.required">
        <div class="row" *ngIf="meta.elementMetadataReg?.required">
          <div class="col-sm-1">
            <input type="checkbox" class="form-control form-input mandatory no-shadow" [(ngModel)]="meta.value">
          </div>
          <div class="col-sm-11">
            <span class="invalid-feedback" style="padding-top: 5px">{{'attachment.metadata.required'|translate}}</span>
          </div>
        </div>
      </div>
      <div class="form-group form-input-group withError" *ngIf="isDate(meta.elementMetadataReg?.type)">
        <input type="text" class="form-control form-input no-shadow"
          [ngClass]="{mandatory: meta.elementMetadataReg?.required}" placeholder="DD/MM/YYYY"
          (ngModelChange)="setNgbMetadata(meta.elementMetadataReg.code, $event)" [ngModel]="getDatePickerValue(meta)"
          ngbDatepicker #i="ngbDatepicker">
        <div (click)="i.toggle()" class="form-input-icon append pointer w-auto">
          <pwc-icon name="calendar_small"></pwc-icon>
        </div>
        <div *ngIf="meta.error" class="invalid-feedback" style="padding-top: 5px">
          {{'attachment.metadata.invalid-format'|translate}}
        </div>
      </div>
    </dd>
  </dl>
</ng-container>