import { HttpResponse } from '@angular/common/http';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { piCalendarSmall, PwcIconsLibrary } from '@pwc/icons';
import { AuthorizationService } from '@pwc/security';
import * as moment from 'moment';
import { isNullOrUndefined } from 'util';
import { AuthorityCodeEnum } from '../../../enums/common/authority-code.enum';
import { DocumentTypeEnum } from '../../../enums/document-type.enum';
import { MetadataFieldType } from '../../../enums/metadata-field-type.enum';
import { validateDate } from '../../../helpers/common/date-utils';
import { getBlobUrl, getIconByFileExtension } from '../../../helpers/common/generic-utils';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DmsElement } from '../../../models/dms-element';
import { DmsFolderRepositoryService } from '../../../services/dms-folder-repository.service';
import { ModalComponent } from '../../common/modals/modal/modal.component';
import { DmsFileRepositoryService } from '../../../services/dms-file-repository.service';
import { LocalDate } from '../../../models/local-date.model';
import { CheckCrossYearResource } from '../../../models/api/check-cross-year-resource';
import { DmsElementResource } from '../../../models/api/documents/dms-element-resource';
import {
  isBooleanMetadata,
  isDateMetadata,
  isNumberMetadata,
  isStringMetadata
} from '../../../helpers/common/metadata-utils';
import { SpinnerEnum } from '../../../helpers/spinner-enum.enum';
import { Comment } from '../../../models/comment/comment';
import { DmsElementMetadata } from '../../../models/dms-element-metadata';
import { SpinnerService } from '../../../modules/spinner/services/spinner.service';
import { SignDocumentComponent } from '../sign-document/sign-document.component';
import { ToastUtils } from '../../../helpers/toast-utils';
import { UploadFile } from '../../../models/upload-file';
import { EventType } from '../../../models/event/event-type';

@Component({
  selector: 'app-document-detail',
  templateUrl: './document-detail.component.html',
  styleUrls: ['./document-detail.component.scss']
})
export class DocumentDetailComponent implements OnInit {

  @Input() currentSphereId: number;
  @Input() currentFolderId: number;
  @Input() currentFiscalYear: number;
  @Input() lawConservation?: boolean;
  @Input() generalCounselDmsSphere?: boolean;

  historyFileId: number;
  fileIds: number[] = [];

  @ViewChild('documentDetailModal') documentDetailModal: ModalComponent;
  @ViewChild('confirmWatermarkModal') confirmWatermarkModal: ModalComponent;
  @ViewChild('pdfViewer') pdfViewer: any;

  @Output() closed: EventEmitter<string> = new EventEmitter<string>();
  @Output() addNewVersion: EventEmitter<DmsElement> = new EventEmitter<DmsElement>();

  @ViewChild('signComponent') signComponent: SignDocumentComponent;

  previewReportUrl: any = '';
  fileLoaded = false;
  canLoadFile = true;
  iconPath: string;
  dmsElement: DmsElement;
  commentList: Comment[] = null;
  fileName = '';
  errorDate = '';
  errorDocumentDate = '';
  errorEndDate = '';
  errorCrossYear = '';
  subject1selectedRole: String = '';
  subject2selectedRole: String = '';
  subject3selectedRole: String = '';
  documentRectificationFlag = false;
  documentVersion: Number = 0;

  superUserComments: boolean = false;
  superUserDocActions: boolean = false;

  startDate: LocalDate;
  endDate: LocalDate;
  documentDate: LocalDate;
  mapMetaDate = new Map<number, LocalDate>();

  position = 0;

  loading: boolean = false;

  modFormazioneList: any[] = [
    { label: 'A - ' + this.translateSrv.instant('documents.detail.labels.creation'), code: 'A' },
    { label: 'B - ' + this.translateSrv.instant('documents.detail.labels.scan'), code: 'B' },
    { label: 'C - ' + this.translateSrv.instant('documents.detail.labels.transaction'), code: 'C' },
    { label: 'D - ' + this.translateSrv.instant('documents.detail.labels.grouping'), code: 'D' }
  ];

  tipoSoggetto: any[] = [
    { label: this.translateSrv.instant('documents.detail.labels.pf'), code: 'PF' },
    { label: this.translateSrv.instant('documents.detail.labels.pg'), code: 'PG' },
    { label: this.translateSrv.instant('documents.detail.labels.pai'), code: 'PAI' },
    { label: this.translateSrv.instant('documents.detail.labels.pae'), code: 'PAE' }
  ];
  ruoloSoggetto1: any[] = [
    //    {label: 'Assegnatario', code: 'Assegnatario'},
    { label: this.translateSrv.instant('documents.detail.labels.author'), code: 'Autore' },
    { label: this.translateSrv.instant('documents.detail.labels.sender'), code: 'Mittente' },
  ];

  ruoloSoggetto2: any[] = [
    { label: this.translateSrv.instant('documents.detail.labels.receiver'), code: 'Destinatario' }
  ];

  ruoloSoggetto3: any[] = [
    { label: 'Autore', code: 'Autore' }
  ];

  valoriSoggetto1PF: string[] = ['soggetto_1_cog_s', 'soggetto_1_nom_s', 'soggetto_1_cf_s', 'soggetto_1_inddig_s'];
  valoriSoggetto1PG: string[] = ['soggetto_1_org_s', 'soggetto_1_piva_s', 'soggetto_1_denuff_s', 'soggetto_1_inddig_s'];
  valoriSoggetto1PAI: string[] = ['soggetto_1_nomamm_s', 'soggetto_1_ipaaoocode_s', 'soggetto_1_ipauorcode_s', 'soggetto_1_inddig_s'];
  valoriSoggetto1PAE: string[] = ['soggetto_1_nomamm_s', 'soggetto_1_denuff_s', 'soggetto_1_inddig_s'];

  valoriSoggetto2PF: string[] = ['soggetto_2_cog_s', 'soggetto_2_nom_s', 'soggetto_2_cf_s', 'soggetto_2_inddig_s'];
  valoriSoggetto2PG: string[] = ['soggetto_2_org_s', 'soggetto_2_piva_s', 'soggetto_2_denuff_s', 'soggetto_2_inddig_s'];
  valoriSoggetto2PAI: string[] = ['soggetto_2_nomamm_s', 'soggetto_2_ipaaoocode_s', 'soggetto_2_ipauorcode_s', 'soggetto_2_inddig_s'];
  valoriSoggetto2PAE: string[] = ['soggetto_2_nomamm_s', 'soggetto_2_denuff_s', 'soggetto_2_inddig_s'];

  valoriSoggetto3PF: string[] = ['soggetto_3_cog_s', 'soggetto_3_nom_s'];


  documentDetailSpinner = SpinnerEnum.DOCUMENT_DETAIL;

  faCircleNotch = faCircleNotch;

  constructor(private dmsRepositoryService: DmsFolderRepositoryService,
    private dmsFileRepositoryService: DmsFileRepositoryService,
    private authorizationService: AuthorizationService,
    private spinnerService: SpinnerService,
    private translate: TranslateService,
    private iconsLibrary: PwcIconsLibrary,
    private translateSrv: TranslateService) {
    iconsLibrary.registerIcons([
      piCalendarSmall
    ]);
  }

  ngOnInit(): void {
    this.checkSuperUser();
  }

  private checkSuperUser(): void {
    this.authorizationService.checkAuthorities(AuthorityCodeEnum.ROLE_DMS_READ_COMMENTS)
      .subscribe((res) => {
        this.superUserComments = res;
      });
    this.authorizationService.checkAuthorities(AuthorityCodeEnum.ROLE_DMS_DOCUMENT_ACTIONS)
      .subscribe((res) => {
        this.superUserDocActions = res;
      });
  }

  private loadDocumentDetail(value: DmsElement) {
    this.dmsElement = value;
    this.fileLoaded = false;
    this.spinnerService.hide(this.documentDetailSpinner);
    this.loading = false;

    if (this.superUserComments) {
      this.dmsFileRepositoryService.searchComments(value.id).subscribe((res) => {
        this.commentList = res;
      });
    }

    this.mapMetaDate = new Map<number, LocalDate>();
    if (!isNullOrUndefined(this.dmsElement.metadata)) {
      this.dmsElement.metadata.forEach(value1 => {
        if (value1 && this.isDateMetadata(value1.elementMetadataReg.type)) {
          const date = moment(value1.value);
          this.mapMetaDate[value1.id] = new LocalDate(date.year(), date.month() + 1, date.date());
        }
      });
    }

    if (!isNullOrUndefined(this.dmsElement.fileHistorySelected)) {
      this.fileName = this.dmsElement.fileHistorySelected.name;
      this.dmsRepositoryService.downloadHistoryFileForPreview(this.dmsElement.fileHistorySelected.id).subscribe((blob: HttpResponse<Blob>) => {
        this.fileLoaded = true;
        this.previewReportUrl = getBlobUrl(blob.body);
      }, (error) => {
        this.canLoadFile = false;
      });
    } else {
      this.fileName = this.dmsElement.name;
      this.dmsRepositoryService.downloadFileForPreview(this.dmsElement.id).subscribe((blob: HttpResponse<Blob>) => {
        this.fileLoaded = true;
        this.previewReportUrl = getBlobUrl(blob.body);
      }, () => {
        this.canLoadFile = false;
      });
    }

    this.iconPath = getIconByFileExtension(this.dmsElement.extension, this.dmsElement.crossYear);
    this.canLoadFile = this.dmsElement.extension != null && (this.dmsElement.extension.toLowerCase().indexOf('pdf') > -1 ||
      this.dmsElement.extension.toLowerCase().indexOf('doc') > -1 ||
      this.dmsElement.extension.toLowerCase().indexOf('docx') > -1);
    if (this.dmsElement.fiscalStartDate != null) {
      this.startDate = new LocalDate(new Date(this.dmsElement.fiscalStartDate).getFullYear(),
        new Date(this.dmsElement.fiscalStartDate).getMonth() + 1, new Date(this.dmsElement.fiscalStartDate).getDate());
    }
    if (this.dmsElement.fiscalEndDate != null) {
      this.endDate = new LocalDate(new Date(this.dmsElement.fiscalEndDate).getFullYear(),
        new Date(this.dmsElement.fiscalEndDate).getMonth() + 1, new Date(this.dmsElement.fiscalEndDate).getDate());
    }

    if (this.dmsElement.documentDate != null) {
      this.documentDate = new LocalDate(new Date(this.dmsElement.documentDate).getFullYear(),
        new Date(this.dmsElement.documentDate).getMonth() + 1, new Date(this.dmsElement.documentDate).getDate());
    } else {
      this.documentDate = null;
    }

    this.changeFn1(this.getMetadataByCode('soggetto_1_tipo_s'));
    this.changeFn2(this.getMetadataByCode('soggetto_2_tipo_s'));
    this.changeFn3(this.getMetadataByCode('soggetto_3_tipo_s'));

    this.resetErrors();
  }

  open(fileIds: number[], historyFileId: number) {
    this.commentList = null;
    this.historyFileId = historyFileId;
    this.fileIds = fileIds;
    this.reloadData();
    this.documentDetailModal.open();
    this.loadData();
  }
  getMetadataByCode(code: string) {
    return this.dmsElement && this.dmsElement.metadata ? this.dmsElement.metadata.find(m => m.elementMetadataReg && m.elementMetadataReg.code === code) : null;
  }

  private reloadData() {
    this.dmsElement = null;
    this.fileName = '';
    this.errorDate = '';
    this.errorCrossYear = '';
    this.position = 0;
    this.fileLoaded = false;
    this.documentRectificationFlag = false;
    this.loading = false;

    this.previewReportUrl = '';
    this.iconPath = getIconByFileExtension('', false);
  }

  private loadData() {
    this.spinnerService.show(this.documentDetailSpinner);
    this.loading = true;

    if (!isNullOrUndefined(this.fileIds)) {
      if (isNullOrUndefined(this.historyFileId)) {
        this.dmsFileRepositoryService.loadFileDetail(this.fileIds[this.position], this.currentFiscalYear)
          .subscribe(value => this.loadDocumentDetail(value));
      } else {
        this.dmsFileRepositoryService.loadFileHistoryDetail(this.fileIds[this.position], this.historyFileId)
          .subscribe(value => this.loadDocumentDetail(value));
      }
    }
  }

  openFolderPreview(folderId: number) {
    this.commentList = null;
    this.historyFileId = null;
    this.fileIds = [];
    this.reloadData();
    this.documentDetailModal.open();

    const body: Partial<DmsElementResource> = {
      sphereId: this.currentSphereId
    };
    body.parentId = folderId;
    body.fiscalYear = this.currentFiscalYear;
    this.dmsRepositoryService.search(body).subscribe(value => {
      value.filter(value1 => value1.documentType === DocumentTypeEnum.FILE).forEach(value1 => this.fileIds.push(value1.id));
      this.loadData();
    });
  }

  onClose($event: string) {
    this.commentList = null;
    this.closed.emit($event);
    this.subject1selectedRole = '';
    this.subject2selectedRole = '';
    this.subject3selectedRole = '';
  }

  forceClose() {
    this.documentDetailModal.close();
  }

  enableChanges(): boolean {
    return this.historyFileId == null && this.checkSharePref();
  }

  get canUploadNewVersion(): boolean {
    return this.historyFileId == null && this.checkSharePref() && !this.dmsElement.isParentAZipFileLawConserved;
  }

  metadataEditable(): boolean {
    // TODO: documentRectificationFlag
    return this.enableChanges() && (!this.isAlreadyLawConservation() || this.documentRectificationFlag);
  }

  isAlreadyLawConservation(): boolean {
    return (this.dmsElement.lawConservation === true || this.dmsElement.isParentAZipFileLawConserved || this.dmsElement.lawConservationAtLeastOneVersion ||
      this.dmsElement.lastLawConservationExecution != null);
  }

  private checkSharePref(): boolean {
    if (isNullOrUndefined(this.dmsElement)) {
      return false;
    } else if (isNullOrUndefined(this.dmsElement.dmsSharing)) {
      return true;
    } else {
      return !isNullOrUndefined(this.dmsElement.dmsSharing) && this.dmsElement.dmsSharing.permissionUpload;
    }
  }

  disableSave(): boolean {
    return (this.dmsElement.fiscalEndDate < this.dmsElement.fiscalStartDate)
      || this.errorDate !== '' || this.errorEndDate !== '' || this.errorDocumentDate !== '' || this.getMetadataErrors();
  }

  private doSave(cb: () => void): void {
    // TODO: check if rectifying
    if (this.dmsElement.fiscalEndDate < this.dmsElement.fiscalStartDate) {
      this.errorDate = this.translateSrv.instant('generic.message.insert-date-error');
    } else if (this.errorDate === '' && this.errorDate === '' && this.errorDocumentDate === '' && !this.getMetadataErrors()) {
      this.errorDate = '';
      this.dmsElement.name = this.fileName;
      this.dmsElement.lawConservatioRectification = this.documentRectificationFlag;
      const resource = this.dmsRepositoryService.convertModelToResource(this.dmsElement);
      this.dmsFileRepositoryService.save(resource).subscribe(cb);
    }
  }

  public onWatermarkConfirmed(): void {
    this.spinnerService.show(this.documentDetailSpinner);

    this.dmsFileRepositoryService.watermark(this.dmsElement.id, this.currentSphereId, this.currentFiscalYear)
      .subscribe(() => {
        this.spinnerService.hide(this.documentDetailSpinner);
        // refresh document
        this.dmsRepositoryService.downloadFileForPreview(this.dmsElement.id).subscribe((blob: HttpResponse<Blob>) => {
          this.fileLoaded = true;
          this.previewReportUrl = getBlobUrl(blob.body);
        }, () => {
          this.canLoadFile = false;
        });
      }
        , error => {
          this.spinnerService.hide(this.documentDetailSpinner);
        }
      );
  }

  save(): void {
    this.doSave(() => {
      this.documentDetailModal.close();
    });
  }

  saveAndNext(): void {
    this.doSave(() => {
      this.nextFile();
    });
  }

  nextFile(): void {
    this.resetData();
    this.applyPageChange(this.position + 1);
  }

  private resetData(): void {
    this.errorDate = '';
    this.errorDocumentDate = '';
    this.errorEndDate = '';
    this.errorCrossYear = '';
    this.startDate = undefined;
    this.endDate = undefined;
    this.documentDate = undefined;
    this.mapMetaDate = new Map<number, LocalDate>();
  }

  prevFile() {
    this.onPageChange(this.position - 1);
  }

  setNgbFiscalStartDate(date: NgbDate) {
    if (date.year >= 1970 && validateDate(date)) {
      this.errorDate = '';
      this.dmsElement.fiscalStartDate = Date.UTC(date.year, date.month - 1, date.day);
      this.checkCrossYearInfo();
    } else {
      this.dmsElement.fiscalStartDate = null;
      this.errorDate = this.translateSrv.instant('generic.message.date-format-error');
    }
  }

  setNgbFiscalEndDate(date: NgbDate) {
    if (date.year >= 1970 && validateDate(date)) {
      this.errorEndDate = '';
      this.dmsElement.fiscalEndDate = Date.UTC(date.year, date.month - 1, date.day);
      this.checkCrossYearInfo();
    } else {
      this.dmsElement.fiscalEndDate = null;
      this.errorEndDate = this.translateSrv.instant('generic.message.date-format-error');
    }
  }

  setNgbMetadata(date: NgbDate, metadataId: number) {
    if (isNullOrUndefined(date)) {
      this.dmsElement.metadata.forEach(value => {
        if (value.id === metadataId) {
          value.error = false;
          value.value = null;
        }
      });
    } else {
      if (date.year >= 1970 && validateDate(date)) {
        this.dmsElement.metadata.forEach(value => {
          if (value.id === metadataId) {
            value.error = false;
            value.value = moment().year(date.year).month(date.month - 1).date(date.day).format('YYYY-MM-DD');
          }
        });
      } else {
        this.dmsElement.metadata.forEach(value => {
          if (value.id === metadataId) {
            value.error = true;
            value.value = null;
          }
        });
      }
    }
  }

  getMetadataErrors(): boolean {
    return this.dmsElement.metadata.filter(value => value.error).length > 0;
  }

  setNgbDocumentDate(date: NgbDate) {
    if (isNullOrUndefined(date)) {
      this.dmsElement.documentDate = null;
      this.errorDocumentDate = '';
    } else {
      if (date.year >= 1970 && validateDate(date)) {
        this.errorDocumentDate = '';
        this.dmsElement.documentDate = Date.UTC(date.year, date.month - 1, date.day);
      } else {
        this.errorDocumentDate = this.translateSrv.instant('generic.message.date-format-error');
      }
    }
  }

  resetErrors(): void {
    this.errorDate = '';
    this.errorEndDate = '';
    this.errorDocumentDate = '';
    this.dmsElement.metadata.forEach(value => value.error = false);
  }

  uploadNewVersion() {
    this.addNewVersion.emit(this.dmsElement);
  }

  documentRectification() {
    this.documentRectificationFlag = true;
    this.dmsElement.lastLawConservationExecution = null;
  }

  checkCrossYearInfo() {
    const checkCrossYear = new CheckCrossYearResource();
    checkCrossYear.fiscalEndDate = this.dmsElement.fiscalEndDate;
    checkCrossYear.fiscalStartDate = this.dmsElement.fiscalStartDate;
    checkCrossYear.parentId = this.currentFolderId;
    checkCrossYear.fiscalYear = this.currentFiscalYear;
    checkCrossYear.sphereId = this.currentSphereId;
    this.dmsFileRepositoryService.checkCrossYear(checkCrossYear).subscribe(value => {
      if (!value.ok) {
        if (value.years.length === 1) {
          this.errorCrossYear = this.translateSrv.instant('generic.message.warning-year');
        } else {
          this.errorCrossYear = this.translateSrv.instant('generic.message.warning-years');
        }
        let years = '';
        value.years.forEach(value1 => {
          years += value1 + ', ';
        });
        this.errorCrossYear += years.substr(0, years.length - 2);
        this.errorCrossYear += ' ' + this.translateSrv.instant('generic.message.no-folder-with-file');
      } else {
        this.errorCrossYear = '';
      }
    });
  }

  private applyPageChange(position: number): void {
    this.spinnerService.show('document-detail');

    if (!isNullOrUndefined(position)) {
      if (position >= 0 && position < this.fileIds.length) {
        this.position = position;
      } else if (position === this.fileIds.length) {
        this.position = 0;
      }
      this.dmsFileRepositoryService.loadFileDetail(this.fileIds[this.position], this.currentFiscalYear)
        .subscribe(value => this.loadDocumentDetail(value));
    }
  }

  onPageChange(position: number): void {
    this.applyPageChange(position - 1);
  }

  isStringMetadata(type: MetadataFieldType) {
    return isStringMetadata(type);
  }

  isBooleanMetadata(type: MetadataFieldType): boolean {
    return isBooleanMetadata(type);
  }

  isNumberMetadata(type: MetadataFieldType): boolean {
    return isNumberMetadata(type);
  }

  isDateMetadata(type: MetadataFieldType): boolean {
    return isDateMetadata(type);
  }

  getDatePickerValue(metadata: DmsElementMetadata): LocalDate {
    return this.mapMetaDate[metadata.id];
  }

  isFilenameValid(filename: string): boolean {
    if ((this.dmsElement.excludeFromLawConservation || this.dmsElement.excludeParentFromLawConservation) ||
      (this.dmsElement.configurationId == null && this.dmsElement.configurationIdParent == null)) {
      return true;
    }

    const regexp: RegExp = /^[a-zA-Z0-9_\-.'@ ]+$/;
    return regexp.test(filename);
  }

  changeFn1(meta: any) {

    if (this.subject1selectedRole !='' && meta !== null ) {
      this.subject1selectedRole = meta.value;

      // 1 - Sulla base del meta.value svuoto le celle delle le altre tipologie

        switch(meta.value) { 
          case 'PF': { 
            this.valoriSoggetto1PG.forEach(val => {
              const sog= this.getMetadataByCode(val); 
            // 3 - seovrascrivere il metadato
               sog.value="";
          })
            this.valoriSoggetto1PAI.forEach(val => {
              const sog= this.getMetadataByCode(val); 
            // 3 - seovrascrivere il metadato
              sog.value="";
          })
            this.valoriSoggetto1PAE.forEach(val => {
              const sog= this.getMetadataByCode(val); 
            // 3 - seovrascrivere il metadato
              sog.value="";
           })
            
             break; 
          } 
          case 'PG': { 
            this.valoriSoggetto1PF.forEach(val => {
              const sog= this.getMetadataByCode(val); 
            // 3 - seovrascrivere il metadato
               sog.value="";
          })
            this.valoriSoggetto1PAI.forEach(val => {
              const sog= this.getMetadataByCode(val); 
            // 3 - seovrascrivere il metadato
              sog.value="";
          })
            this.valoriSoggetto1PAE.forEach(val => {
              const sog= this.getMetadataByCode(val); 
            // 3 - seovrascrivere il metadato
              sog.value="";
           }) 
             break; 
          } 
          case 'PAI': { 
            this.valoriSoggetto1PG.forEach(val => {
              const sog= this.getMetadataByCode(val); 
            // 3 - seovrascrivere il metadato
               sog.value="";
          })
            this.valoriSoggetto1PF.forEach(val => {
              const sog= this.getMetadataByCode(val); 
            // 3 - seovrascrivere il metadato
              sog.value="";
          })
            this.valoriSoggetto1PAE.forEach(val => {
              const sog= this.getMetadataByCode(val); 
            // 3 - seovrascrivere il metadato
              sog.value="";
           }) 
            break; 
          } 
          case 'PAE': { 
            this.valoriSoggetto1PG.forEach(val => {
              const sog= this.getMetadataByCode(val); 
            // 3 - seovrascrivere il metadato
               sog.value="";
          })
            this.valoriSoggetto1PF.forEach(val => {
              const sog= this.getMetadataByCode(val); 
            // 3 - seovrascrivere il metadato
              sog.value="";
          })
            this.valoriSoggetto1PAI.forEach(val => {
              const sog= this.getMetadataByCode(val); 
            // 3 - seovrascrivere il metadato
              sog.value="";
           })  
            break; 
          } 
          default: { 
             //statements; 
             break; 
          } 
       } 

    } else {
      this.subject1selectedRole=meta.value;
    }
  }
  changeFn2(meta: any) {

    if (this.subject2selectedRole !='' && meta !== null && meta.value !== null) {
      this.subject2selectedRole = meta.value;

      // 1 - Sulla base del meta.value svuoto le celle delle le altre tipologie

        switch(meta.value) { 
          case 'PF': { 
            this.valoriSoggetto2PG.forEach(val => {
              const sog= this.getMetadataByCode(val); 
              if (sog !== null) {
                sog.value="";
                // 3 - seovrascrivere il metadato
              }
          })
            this.valoriSoggetto2PAI.forEach(val => {
              const sog= this.getMetadataByCode(val); 
              if (sog !== null) {
                sog.value="";
                // 3 - seovrascrivere il metadato
              }
          })
            this.valoriSoggetto2PAE.forEach(val => {
              const sog= this.getMetadataByCode(val); 
              if (sog !== null) {
                sog.value="";
                // 3 - seovrascrivere il metadato
              }
           })
            
             break; 
          } 
          case 'PG': { 
            this.valoriSoggetto2PF.forEach(val => {
              const sog= this.getMetadataByCode(val); 
              if (sog !== null) {
                sog.value="";
                // 3 - seovrascrivere il metadato
              }
          })
            this.valoriSoggetto2PAI.forEach(val => {
              const sog= this.getMetadataByCode(val); 
              if (sog !== null) {
                sog.value="";
                // 3 - seovrascrivere il metadato
              }
          })
            this.valoriSoggetto2PAE.forEach(val => {
              const sog= this.getMetadataByCode(val); 
              if (sog !== null) {
                sog.value="";
                // 3 - seovrascrivere il metadato
              }
           }) 
             break; 
          } 
          case 'PAI': { 
            this.valoriSoggetto2PG.forEach(val => {
              const sog= this.getMetadataByCode(val); 
              if (sog !== null) {
                sog.value="";
                // 3 - seovrascrivere il metadato
              }
          })
            this.valoriSoggetto2PF.forEach(val => {
              const sog= this.getMetadataByCode(val); 
              if (sog !== null) {
                sog.value="";
                // 3 - seovrascrivere il metadato
              }
          })
            this.valoriSoggetto2PAE.forEach(val => {
              const sog= this.getMetadataByCode(val); 
              if (sog !== null) {
                sog.value="";
                // 3 - seovrascrivere il metadato
              }
           }) 
            break; 
          } 
          case 'PAE': { 
            this.valoriSoggetto2PG.forEach(val => {
              const sog= this.getMetadataByCode(val); 
              if (sog !== null) {
                sog.value="";
                // 3 - seovrascrivere il metadato
              }
          })
            this.valoriSoggetto2PF.forEach(val => {
              const sog= this.getMetadataByCode(val); 
              if (sog !== null) {
                sog.value="";
                // 3 - seovrascrivere il metadato
              }
          })
            this.valoriSoggetto2PAI.forEach(val => {
              const sog= this.getMetadataByCode(val); 
              if (sog !== null) {
                sog.value="";
                // 3 - seovrascrivere il metadato
              }
           })  
            break; 
          } 
          default: { 
             //statements; 
             break; 
          } 
       } 

    } else {
      if (meta && meta.value) {
        this.subject2selectedRole=meta.value;
      }
    }
  }
  changeFn3(val) {
    this.subject3selectedRole = val;
  }

  getSubject1selectedRole(val): boolean {
    return this.subject1selectedRole === val;
  }
  getSubject2selectedRole(val): boolean {
    return this.subject2selectedRole === val;
  }
  getSubject3selectedRole(val): boolean {
    return this.subject3selectedRole === val;
  }

  getMetadata(code): DmsElementMetadata {
    this.dmsElement.metadata.forEach(value => {
      if (value && value.elementMetadataReg.code === code) {
        return value;
      }
    });
    return null;
  }
  sortMetadata(): DmsElementMetadata[] {
    const sortedMetadata = [];
    const sortedOtherMetadata = [];
    const soggetto1Meta = [];
    const soggetto2Meta = [];
    const soggetto3Meta = [];
    const sogg1ruolo = [];
    const sogg1tipo = [];
    const sogg2ruolo = [];
    const sogg2tipo = [];
    const sogg3ruolo = [];
    const sogg3tipo = [];
    const rectificationMeta = [];

    // get document version meta value. If not associated to the file, is set to 0
    this.documentVersion = Number(this.getMetadataByCode('versione_documento_l'));

    soggetto1Meta.push(this.dmsElement.metadata.filter(x =>  x && x?.elementMetadataReg?.code?.startsWith('soggetto_1_')
      && !x?.elementMetadataReg?.code?.startsWith('soggetto_1_ruolo')
      && !x?.elementMetadataReg?.code?.startsWith('soggetto_1_tipo')
    ));
    sogg1ruolo.push(this.dmsElement.metadata.filter(x => x && x?.elementMetadataReg?.code?.startsWith('soggetto_1_ruolo')));
    sogg1tipo.push(this.dmsElement.metadata.filter(x => x && x?.elementMetadataReg?.code?.startsWith('soggetto_1_tipo')));
    let array = [];
    array = soggetto1Meta[0];
    array.sort((a, b) => {
      if (a && b && a?.elementMetadataReg?.name > b?.elementMetadataReg?.name) {
        return -1;
      } else {
        return 1;
      }
    });
    soggetto1Meta[0] = array;

    soggetto2Meta.push(this.dmsElement.metadata.filter(x => x.elementMetadataReg?.code?.startsWith('soggetto_2_')
      && !x.elementMetadataReg?.code?.startsWith('soggetto_2_tipo')
      && !x.elementMetadataReg?.code?.startsWith('soggetto_2_ruolo')
    ));
    sogg2ruolo.push(this.dmsElement.metadata.filter(x => x && x?.elementMetadataReg?.code?.startsWith('soggetto_2_ruolo')));
    sogg2tipo.push(this.dmsElement.metadata.filter(x => x && x?.elementMetadataReg?.code?.startsWith('soggetto_2_tipo')));

    array = soggetto2Meta[0];
    array.sort((a, b) => {
      if (a.elementMetadataReg?.name > b.elementMetadataReg?.name) {
        return -1;
      } else {
        return 1;
      }
    });
    soggetto2Meta[0] = array;

    // in case the document is already rectified or is being rectified now
    if (this.documentVersion > 1 || this.documentRectificationFlag) {
      // soggetto 3 metadata other than type & role
      soggetto3Meta.push(this.dmsElement.metadata.filter(x => x && x?.elementMetadataReg?.code?.startsWith('soggetto_3_')
        && !x?.elementMetadataReg?.code?.startsWith('soggetto_3_ruolo')
        && !x?.elementMetadataReg?.code?.startsWith('soggetto_3_tipo')
      ));
      // rectification metadata. Missing versione_documento_l, data_mod_dt, id_doc_versprec_s which should not be visible onto preview
      rectificationMeta.push(this.dmsElement.metadata.filter(x => x && x?.elementMetadataReg?.code?.startsWith('tipo_modifica_s')
        || x?.elementMetadataReg?.code?.startsWith('soggetto_autore_mod_s')));
      // soggetto 3 tipo & ruolo
      sogg3ruolo.push(this.dmsElement.metadata.filter(x => x && x?.elementMetadataReg?.code?.startsWith('soggetto_3_ruolo')));
      sogg3tipo.push(this.dmsElement.metadata.filter(x => x && x?.elementMetadataReg?.code?.startsWith('soggetto_3_tipo')));
      let array = [];
      array = soggetto3Meta[0];
      array.sort((a, b) => {
        if (a && b && a?.elementMetadataReg?.name > b?.elementMetadataReg?.name) {
          return -1;
        } else {
          return 1;
        }
      });
      soggetto3Meta[0] = array;
    }


    sortedOtherMetadata.push(this.dmsElement.metadata.filter(x => !(x.elementMetadataReg?.code?.startsWith('soggetto_') || x.elementMetadataReg.code?.startsWith('tipo_modifica_s'))));

    array = sortedOtherMetadata[0];
    array.sort((a, b) => {
      if ( a && b && a?.elementMetadataReg?.name > b?.elementMetadataReg?.name) {
        return -1;
      } else {
        return 1;
      }
    });
    sortedOtherMetadata[0] = array;


    sortedMetadata.push(sogg1ruolo.pop().pop());
    sortedMetadata.push(sogg1tipo.pop().pop());
    array = soggetto1Meta[0];
    for (let i = 0; 0 < array.length; i++) {
      sortedMetadata.push(array.pop());
    }

    if (sogg2ruolo.length != 0) {
      sortedMetadata.push(sogg2ruolo.pop().pop());
    }
    if (sogg2tipo.length != 0) {
      sortedMetadata.push(sogg2tipo.pop().pop());
    }

    array = soggetto2Meta[0];
    for (let i = 0; 0 < array.length; i++) {
      sortedMetadata.push(array.pop());
    }

    if (this.documentVersion > 1 || this.documentRectificationFlag) {
      // add soggetto 3 meta to preview
      sortedMetadata.push(sogg3ruolo.pop().pop());
      sortedMetadata.push(sogg3tipo.pop().pop());
      array = soggetto3Meta[0];
      for (let i = 0; 0 < array.length; i++) {
        sortedMetadata.push(array.pop());
      }
      // add also other rectification metadata to preview
      array = rectificationMeta[0];
      for (let i = 0; 0 < array.length; i++) {
        sortedMetadata.push(array.pop());
      }
    }
    array = sortedOtherMetadata[0];
    for (let i = 0; 0 < array.length; i++) {
      sortedMetadata.push(array.pop());
    }
    return sortedMetadata;
  }
}
